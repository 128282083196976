import React, { useEffect, useRef, useState } from 'react';

const useElementOnScreen = (options) => {
    const isBuildProcess = navigator.userAgent === 'ReactSnap';
    const containerRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const callback = (entries) => {
        const [entry] = entries;

        // if we're building the static HTML, we never want to change things based on visibility
        // we just take the  first value (false), and leave it there
        if (isBuildProcess) setIsVisible(false);
        else setIsVisible(entry.isIntersecting);
    }

    // useEffect(() => {
    //     console.log('isVisible?', isVisible);
    // }, [isVisible])

    options = options || {
        root: null,
        rootMargin: '0px',
        threshold: [.1, .9]
    }

    useEffect(() => {
        const observer = new IntersectionObserver(callback, options);
        const container = containerRef.current;
        if (container) observer.observe(container);

        return () => {
            if (container) observer.unobserve(container);
        }
    })

    return [containerRef, isVisible];
}

function ImageContainer({ background, children, hasBorder, isBanner, align, backgroundIsOverlayed, caption }) {
    var styles = {};
    if (background) styles.backgroundImage = 'url('+ background+ ')';

    var optionalClasses = '';
    if (hasBorder) optionalClasses += ' article-section-image-container-border';
    if (isBanner) optionalClasses += ' article-section-image-container-banner';
    if (align) optionalClasses += ' article-section-image-container-' + align;
    if (backgroundIsOverlayed) optionalClasses += ' article-section-image-container-background-overlay';

    // const [ containerRef, isVisible ] = useElementOnScreen();

    return (
        <div className={'article-section-image-container' + optionalClasses} style={styles}>
            {children}
            {caption &&
                <p className="article-section-image-caption">{caption}</p>
            }
        </div>
    )
}

export function Gallery({ hasBorder, items, isPreview }) {
    const [ index, setIndex ] = useState(0);

    // var activeItem;
    // useEffect(() => {
    //     console.log(index, items, items[index], items[index].item, activeItem);
    //     activeItem = items[index].item;
    // }, [index]);

    return <>
        {/* {items[index].item} */}
        {React.cloneElement(items[index].item, { key: index })}
        {!isPreview &&
            <nav className="article-section-gallery-nav">
                {items.map((item, i) => {
                    return (
                        <button className={'article-section-gallery-button' + (i === index ? ' article-section-gallery-button-active' : '')} onClick={() => { setIndex(i) }} key={i}>
                            <picture>
                                <img className="article-section-gallery-thumbnail" src={item.thumbnail} loading="lazy" alt="Thumbnail" width="80" height="80" />
                            </picture>
                        </button>
                    )
                })}
            </nav>
        }
    </>
}

export function Image({ alt, src, hasBorder, isBanner, hide, caption }) {
    if (hide) return;
    const breakpoint = 640;

    // function onLoad(e) {
    //     console.log('loaded', e);
    // }

    return (
        <ImageContainer hasBorder={hasBorder} isBanner={isBanner} background={src.placeholder} caption={caption}>
            <picture>
                {src.small && <source media={`(max-width: ${breakpoint-1}px)`} srcSet={ src.small } /> }
                {src.large && <source media={`(min-width: ${breakpoint}px)`} srcSet={ src.large } /> }
                <img src={src.large} loading="lazy" className="article-section-image" alt={alt || ''} />
            </picture>
        </ImageContainer>
    )
}

export function Video({ align, src, hasBorder, isBanner, backgroundIsOverlayed, caption }) {
    const [ videoEl, isVisible ] = useElementOnScreen();
    const [ sources, setSources ] = useState(false);
    const [ isPlaying, setIsPlaying ] = useState(false);

    // when it enters/leaves the visible stage
    useEffect(() => {
        // bug where the muted attribute doesn't work in React :(
        videoEl.current.defaultMuted = true;
        videoEl.current.muted = true;

        // console.log('isVisible?', isVisible);
        if (isVisible) {
            if (!sources) {
                // when it becomes visible for the first time we set the sources
                // console.log('visible for the first time');
                var newSources = [];
                if (src.webm) newSources.push({ src: src.webm, type: 'video/webm' });
                if (src.mp4)  newSources.push({ src: src.mp4,  type: 'video/mp4' });
                setSources(newSources);
                videoEl.current.load();
                setIsPlaying(true);
            } else if (!isPlaying) {
                // otherwise, we just play it
                // console.log('play');
                videoEl.current.play()
                    .then(() => { setIsPlaying(true) })
                    .catch(e => { console.log('Couldn\'t play video', e) });
            }
        } else if (isPlaying) {
            // when it leaves the visible stage, it's paused
            // console.log('pause');
            videoEl.current.pause();
            setIsPlaying(false);
        }
    }, [isVisible, sources, src, videoEl, isPlaying])

    // useEffect(() => {
    //     // when the source is set, we need to trigger loading for it to start
    //     if (sources) {
    //         console.log('load');
    //         videoEl.current.load();
    //     }
    // }, [sources, videoEl])

    return (
        <ImageContainer hasBorder={hasBorder} isBanner={isBanner} background={src.background} align={align} backgroundIsOverlayed={backgroundIsOverlayed} caption={caption}>
            <video autoPlay muted loop playsInline poster={src.poster} className={'article-section-video article-section-video-' + align} ref={videoEl}>
                {sources && sources.map((source, i) => {
                    return <source {...source} key={i} />
                })}
            </video>
        </ImageContainer>
    )
}